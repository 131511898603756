<template>
  <div class="ClassSummary">
	<div class="head">
        <div class="list-title">
            <ul>
                <li @click="_tabsActive('Individual')" :class="tabsActive == 'Individual' ? 'active' : ''">上课汇总-个人版</li>
                <li @click="_tabsActive('StudentAttendCost')" :class="tabsActive == 'StudentAttendCost' ? 'active' : ''">学员出勤率</li>
                <li @click="_tabsActive('TeacherClassStat')" :class="tabsActive == 'TeacherClassStat' ? 'active' : ''">老师带班统计</li>
            </ul>
        </div>
        <div class="line"></div>
        
    </div>

    <div class="explain-text">
        <a-icon type="info-circle"/>
        <span> 前页面数据仅统计截止至当前时间 </span>
        <a href="#">计算方式</a>
    </div>

    <div class="sub-title">
        <ul>
            <li @click="_tabsSubActive('date')" :class="tabsSubActive == 'date' ? 'active' : ''"><a>日期</a><span>|</span></li>
            <li @click="_tabsSubActive('course')" :class="tabsSubActive == 'course' ? 'active' : ''"><a>课程</a><span>|</span></li>
            <li @click="_tabsSubActive('teacher')" :class="tabsSubActive == 'teacher' ? 'active' : ''"><a>老师</a><span>|</span></li>
            <li @click="_tabsSubActive('class')" :class="tabsSubActive == 'class' ? 'active' : ''"><a>班级</a></li>
        </ul>
    </div>
    <div class="stat-data">
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{TotalArrangeClassCount}}</p>
                <p class="stat-text-title">排课次数总计</p>
            </div>
        </div>
        <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">{{TotalActualClassBeginsCount}}</p>
                <p class="stat-text-title">实际上课总计</p>
            </div>
            
        </div>
        <!-- <div class="stat-item">
            <div class="stat-text">
                <p class="stat-num">433</p>
                <p class="stat-text-title">耗课金额总计</p>
            </div>
        </div> -->
    </div>

    <div class="chart">
        <div style="margin-left: 14px;">
            <span style="font-weight:bold;color: #40a9ff;">|</span>
            <span style="font-weight: 300;color: #242424;font-size: 12px;margin-left: 5px;">上课次数趋势-出勤率</span>
        </div>

        <div id="echarts-data" style="width:100%;height: 100%;"></div>
    </div>

    <div class="table-list">
        <a-table :columns="columns" :data-source="data" :pagination="false"></a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 35px;text-align: right;height: 50px;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>

    <!-- <ClassSummaryData v-show="tabsSubActive" :queryData="queryData"></ClassSummaryData> -->

  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'
import ClassSummaryData from 'pages/ReportCenter/ClassSummaryData'
export default {
	name: 'ClassSummary',
    components: { ClassSummaryData },
    data () {
        return {
            tabsActive:'Individual',
            tabsSubActive:'',
            columns:[
                {
                    dataIndex: 'Num',
                    key: 'Num',
                    title: '序号'
                },
                {
                    dataIndex: 'StudentName',
                    key: 'StudentName',
                    title: '姓名'
                },
                {
                    title: '上课次数总计',
                    dataIndex: 'ClassBeginsCount',
                    key: 'ClassBeginsCount',
                },
                {
                    title: '实际扣课次数',
                    dataIndex: 'ActualDeductCount',
                    key: 'ActualDeductCount',
                },
                {
                    title: '实际上课次数',
                    dataIndex: 'ActualClassBeginsCount',
                    key: 'ActualClassBeginsCount',
                },
                {
                    title: '课耗金额',
                    dataIndex: 'ClassCostMoney',
                    key: 'ClassCostMoney',
                },
                {
                    title: '平均每天上课次数',
                    dataIndex: 'AvgDayClassCout',
                    key: 'AvgDayClassCout',
                },
                {
                    title: '平均每天课耗金额',
                    dataIndex: 'AvgDayClassCostMoney',
                    key: 'AvgDayClassCostMoney',
                },
                // {
                //     title: '课耗排名',
                //     dataIndex: 'ClassCostRank',
                //     key: 'ClassCostRank',
                // },
            ],
            data :[],
            queryData:null,
            StartDate:'',
            EndDate:'',
            TotalActualClassBeginsCount:0,//实际上课总计
            TotalArrangeClassCount:0,//排课次数总计
            DataCount:0,
            currPage:1,
        }
    },
    created () {
        this.StartDate = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');
        this.EndDate   = moment().format('YYYY-MM-DD');

        this._getData();

        this._getDataList();
    },
    mounted(){
        this._infoEcharts('sdfdsfs')
    },
    methods: {
        moment,
        _tabsActive(label){
            this.tabsActive = label;
        },
        _tabsSubActive(label){
            this.tabsSubActive = label;
            this.$router.push({
                path: "/ClassSummaryData",
                query: {func:label},
            });
        },
        _infoEcharts(data) {
            let echarts = require('echarts')
            let myChart = echarts.init(document.getElementById('echarts-data'),null,null);

            let option = {
                backgroundColor: '', //背景颜色透明
                tooltip: {
                    trigger: 'axis' //鼠标经过提示
                },
                grid: {
                    x: 45,
                    y: 25,
                    x2: 45,
                    y2: 35
                },
                xAxis: {
                    type: 'category',
                    data: data[0]
                },
                yAxis: {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle:{
                            type:'dashed'
                        }
                    }
                },
                series: [{
                    data: data[1],
                    type: 'line'
                }]
            };

            option && myChart.setOption(option);
        },
        _getData(){
            let that = this;
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:'ClassSummary|Individual|index',
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        that.TotalArrangeClassCount = res.data.data.stat.TotalArrangeClassCount;
                        that.TotalActualClassBeginsCount = res.data.data.stat.TotalActualClassBeginsCount;
                        that._infoEcharts(res.data.data.chart);
                    } else {
                        that.TotalArrangeClassCount = 0;
                        that.TotalActualClassBeginsCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _getDataList(){
            this.currPage > 1 ? this.$message.loading('拉取数据中...') :null;
            this.$axios.post(8000101,{
                    LabelName:'ClassSummary|Individual|list',
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    Page:this.currPage
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.data = res.data.data.list;
                        this.DataCount = res.data.data.count;
                    } else {
                        this.data = [];
                        this.DataCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this._getDataList();
        },
    }
}
</script>

<style lang="less" scoped>
.ClassSummary {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #FFF;
}

.list-title{
    height:50px;
}
.list-title > ul{
    line-height: 50px;
    height: 100%;
    margin-left: 20px;
}

.list-title > ul > li{
    float:left;
    padding-right: 30px;
    font-family: '.PingFang SC ', '.PingFang SC', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
}

.line{
    background-color: #DDD;
    height: 1px;
    width: 100%;
}

.active{
    color:#2994FF;
}

.sub-title{
    height:50px;
}

.sub-title >ul{
    height:100%;
    line-height:50px;
    margin-left: 20px;
}

.sub-title > ul > li{
    float:left;
    font-family: '.PingFang SC ', '.PingFang SC', sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 14px;
    text-align: left;
}

.sub-title > ul > li > span{
    margin: 9px;
}

.stat-data{
    width: 80%;
    background-color: #eff6ff;
    margin-left: 20px;
    height:150px;
    padding:30px;
    margin-top: 20px;
}

.stat-item{
    width: 200px;
    display: -ms-flexbox;
    height: 90px;
    background-color: #ffffff;
    margin-right: 20px;
    display: inline-block;
}
.stat-text{
    height: 50px;
    width: 100px;
    margin: 25px;
}
.stat-num{
    font-weight: 600;
    font-size:18px;
}
.stat-text-title{
    font-weight: 300;
    font-size: 12px;
}
.chart{
    width: 77%;
    margin-left: 5px;
    margin-top: 30px;
    height: 260px;
}
.table-list{
    width:80%;
    margin-top: 30px;
    margin-left: 20px;
}

.explain-text{
    position: absolute;
    top: 8%;
    right: 3%;
}

.explain-text > span{
    font-weight: 300;
    font-style: normal;
    font-size: 12px;
    color: #9D9D9D;
}

.explain-text > a{
    font-weight: 300;
    font-style: normal;
    font-size: 11px;
    color: #2994FF;
}
</style>
