<template>
  <div class="ClassSummaryData">
    <div class="search-item">

        <a-select show-search v-model="SelectId" style="width: 120px;margin-right: 10px;" @change="handleSelectChange" :filter-option="filterOption" allowClear v-if="func == 'date' || func == 'teacher'">
            <a-select-option value="">全部老师</a-select-option>
            <a-select-option v-for="(item,index) in SelectDataList" :value="item.Id">{{item.Name}}</a-select-option>
        </a-select>

        <a-select show-search v-model="SelectId" style="width: 120px;margin-right: 10px;" @change="handleSelectChange" :filter-option="filterOption" allowClear v-if="func == 'course'">
            <a-select-option value="">全部课程</a-select-option>
            <a-select-option v-for="(item,index) in SelectDataList" :value="item.Id">{{item.Name}}</a-select-option>
        </a-select>

        <a-select show-search v-model="SelectId" style="width: 120px;margin-right: 10px;" @change="handleSelectChange" :filter-option="filterOption" allowClear v-if="func == 'class'">
            <a-select-option value="">全部班级</a-select-option>
            <a-select-option v-for="(item,index) in SelectDataList" :value="item.Id">{{item.Name}}</a-select-option>
        </a-select>

        <a-range-picker :ranges="RangesConf" @change="onDateChange" style="margin-right: 10px;" :defaultValue="defaultDate"/>

        <a-button style="margin-right: 10px;" @click="reset">重 置</a-button>

        <a-button type="primary" @click="_search">搜 索</a-button>

    </div>
	<div class="table-list">
        <a-table :columns="DataFormat" :data-source="dataList" :pagination="false"></a-table>
        <a-pagination v-model="currPage" v-if="DataCount > 10" style="margin-top: 35px;text-align: right;" @change="_changePage" :total="DataCount"  show-less-items />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Vue from 'vue'
import moment from 'moment'

export default {
	name: 'ClassSummaryData',
    data () {
        return {
            dataList :[],
            RangesConf:{},
            SelectId:'',
            StartDate:undefined,
            EndDate:undefined,
            defaultDate:[moment().week(moment().week()).startOf('week'),moment()],
            currPage:1,
            DataCount:0,
            LabelName:'ClassSummary|Individual|',
            OrgIds:'',
            SelectDataList:[],
            func:'',
            DataFormat:[],
        }
    },
    props: ['queryData'],
    watch: {
        queryData: {
            immediate: true,
            handler (val) {
                
            },
            deep: true
        }
    },
    created () {
        console.log(this.LabelName + this.$route.query.func)
        this.func = this.$route.query.func;
        this.RangesConf = {
            '上上月': [
                moment().subtract(2,'months').startOf('month'),
                moment().subtract(2,'months').endOf('month')
            ],
            '上月':[
                moment().subtract(1,'months').startOf('month'),
                moment().subtract(1,'months').endOf('month')
            ],
            '本月':[
                moment().startOf("month"),
                moment().endOf('month')
            ]
        }

        this.StartDate = moment().week(moment().week()).startOf('week').format('YYYY-MM-DD');
        this.EndDate   = moment().format('YYYY-MM-DD');

        this._getData();
    },
    mounted(){
        
    },
    methods: {
        moment,
        onDateChange(v){
            if (v.length > 0) {
                this.StartDate = this.__moment__(v[0]).format('YYYY-MM-DD');
                this.EndDate = this.__moment__(v[1]).format('YYYY-MM-DD');
            }
        },
        handleSelectChange(v){
            this.SelectId = v;
        },
        reset(){
            this.SelectId = '';
        },
        _search(){
            this.currPage = 1;
            this._getData();
        },
        _getData(){
            this.$message.loading('拉取数据中...')
            this.$axios.post(8000101,{
                    LabelName:this.LabelName + this.$route.query.func,
                    OrgIds:this.OrgIds,
                    StartDate:this.StartDate,
                    EndDate:this.EndDate,
                    SelectId:this.SelectId,
                    Page:this.currPage
                },
                res => {
                    console.log(res)
                    if (res.data.code == 1) {
                        this.dataList = res.data.data.list;
                        this.DataCount = res.data.data.count;
                        this.SelectDataList = res.data.data.SelectDataList;
                        this.DataFormat = res.data.data.DataFormat;
                    } else {
                        this.DataFormat = res.data.data.DataFormat;
                        this.dataList = [];
                        this.DataCount = 0;
                    }
                    this.$message.destroy()
                }
            )
        },
        _changePage(p){
            this.currPage = p;
            this. _getData();
        },
        filterOption(input, option) {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        },
    }
}
</script>

<style lang="less" scoped>
.ClassSummaryData {
    width: 100%;
    min-height: 100%;
    background-size: 100%;
    position: relative;
    border-radius: 5px;
    background-color: #FFF;
}

.table-list{
    width:90%;
    margin-top: 30px;
    margin-left: 20px;
}

.search-item{
    margin-left: 19px;
    margin-top: 33px;
    display: inline-flex;
}

</style>
